import React from "react"
import loadable from "@loadable/component"
import Headset from "../../../components/pictos/headset.component"
import { ModalButton } from "../../../components/modal/modal.component"
import { Bold } from "../../structure/theme/styles.component"
import { useCoBrand } from "../../coBrand/coBrand.provider"
import OverlayPicto from "../overlayPicto.component"
import { Trans, useTranslation } from "../../translation/translate.component"

const WebCallbackModal = loadable(() => import(`./webCallbackModal.component`), { ssr: false })

export default function WebCallback () {
  const { t } = useTranslation()
  const { coBrand } = useCoBrand()

  if (coBrand && !coBrand.wcbCode) {
    return null
  }

  return (
    <ModalButton
      modalComponent={WebCallbackModal}
      modalProps={{
        partnerCode: APP_CONFIG.webcallbackPartnerCodes.sales,
        schedules: t(APP_CONFIG.schedules.sales),
      }}
    >
      {(onClick, ref) => (
        <OverlayPicto
          data-testid="webcallback_container"
          onClick={onClick}
          ref={ref}
          tooltip={<Trans t={t} i18nKey="common:webcallback.picto_tooltip" components={{ strong: <Bold /> }} />}
        >
          <Headset size={35} color="#FFF" />
        </OverlayPicto>
      )}
    </ModalButton>
  )
}
